import React from "react";
import "../../../Style/Services/SecendSection.css";

function SecendSection() {
  return (
    <>
    <div className="SecendSection">
      <span className="sub-title">
        Electrical contracting some of which are low voltage distribution,
        emergency power systems, interior lighting, lighting control systems
        etc. An electrical contracting is a business that performs specialized
        construction work related to the design, installation, and maintenance
        of electrical systems.
      </span>
    </div>
      <div className="hr-line"></div>
       </>
  );
}

export default SecendSection;
