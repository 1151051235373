import React from "react";
import "../../../Style/Services/SecendSection.css";

function SecendSection() {
  return (
    <div className="SecendSection">
      <h1><span className="blue-sub-title">GOVERNMENT-APPROVED <br /> LICENSED</span> FIREFIGHTING <br /> CONTRACTOR </h1>
    </div>
  );
}

export default SecendSection;
