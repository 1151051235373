import React from "react";
import "../../../Style/Services/Products.css";
import FixedSpeedcontrolpanel from "../../../Assets/Products/Fixed Speed control panel.svg";
import MultipumpPressure from "../../../Assets/Products/Multi-pump Pressure.svg";
import Dewatering from "../../../Assets/Products/Dewatering Pump System.svg";

function ProductList1() {
  const products = [
    {
      Productname: "Fixed Speed <br /> control panel",
      img: FixedSpeedcontrolpanel,
    },
    {
      Productname: "Multi-pump Pressure <br />  Boosting control panel",
      img: MultipumpPressure,
    },
    {
      Productname: "Dewatering Pump System <br />  Control Panel",
      img: Dewatering,
    },
  ];

  return (
    <div className="CPP-Products">
      <div className="CPP-products-list">
        {products.map((product, index) => (
          <div key={index} className="CPP-product-card">
            <img className="CPP-product-img" alt="img" src={product.img} />
            <h6>
              {product.Productname.split("<br />").map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </h6>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductList1;
