import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { HashLink as NavLink } from "react-router-hash-link";
import "../Style/Navbar.css";
import logo from "../Assets/logo.png";

function MainNavbar() {
  const [showNav, setShowNav] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = scrollPos > currentScrollPos || currentScrollPos < 300;
      setShowNav(visible);
      setScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPos]);

  const handleNavClick = (link) => {
    setActiveLink(link);
    setExpanded(false);
  };

  return (
    <Navbar
      expanded={expanded}
      onToggle={setExpanded}
      expand="lg"
      style={{ display: showNav ? "block" : "none" }}
    >
      <Container>
        <Navbar.Brand className="nav-logo" to={"/"}>
          {" "}
          <img
            alt="AG"
            src={logo}
            width="45"
            height="45"
            className="d-inline-block align-top nav-logo-img"
          />{" "}
          <span>AG CORPORATION</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav-right-side">
            <NavLink
              onClick={() => handleNavClick("Home")}
              className={`nav-links ${activeLink === "Home" ? "active" : ""}`}
              to={"/#carousel"}
            >
              Home
            </NavLink>
            <NavDropdown title="Pumping Solutions" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <NavLink
                  onClick={() => handleNavClick("Control Panels")}
                  className={`dropdown-item ${
                    activeLink === "Control Panels" ? "active" : ""
                  }`}
                  to={"/control-Panels-for-plumbing-solutions"}
                >
                  Control Panels for plumbing solutions
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink
                  onClick={() => handleNavClick("Wilo pumps")}
                  className={`dropdown-item ${
                    activeLink === "Wilo pumps" ? "active" : ""
                  }`}
                  to={"/wilo-pumps#service-header"}
                >
                  Wilo pumps
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink
              onClick={() => handleNavClick("Electrical Control Panel")}
              className={`nav-links ${
                activeLink === "Electrical Control Panel" ? "active" : ""
              }`}
              to={"/electrical-control-panel#service-header"}
            >
              Electrical Control Panel
            </NavLink>
            <NavLink
              onClick={() => handleNavClick("Fire Fighting Services")}
              className={`nav-links ${
                activeLink === "Fire Fighting Services" ? "active" : ""
              }`}
              to={"/fire-fighting-services#service-header"}
            >
              Fire Fighting Services
            </NavLink>
            <NavLink
              onClick={() => handleNavClick("Electrical Services")}
              className={`nav-links ${
                activeLink === "Electrical Services" ? "active" : ""
              }`}
              to={"/electrical-services#service-header"}
            >
              Electrical Services
            </NavLink>
            <NavLink
              onClick={() => handleNavClick("After Sales Services")}
              className={`nav-links ${
                activeLink === "After Sales Services" ? "active" : ""
              }`}
              to={"/after-sales-services#service-header"}
            >
              After Sales Services
            </NavLink>
            <NavLink
              onClick={() => handleNavClick("Panel Tracker")}
              className={`nav-links ${
                activeLink === "Panel Tracker" ? "active" : ""
              }`}
              to={"/panelqr/login"}
            >
              Panel Tracker
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
